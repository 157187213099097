:root {
  --background-primary: #282828;
  --border-primary: #282828;
  --tabContainer: #242222;
  --inner-shadow: 1px 1px 5px #000000, inset -5px -5px 5px hsla(0, 2%, 8%, 0.5),
    inset 1px 1px 10px rgba(0, 0, 0, 0.1);
  --outer-shadow: -5px -5px 10px #111111, 1px 1px 10px rgba(0, 0, 0, 0.1),
    inset 1px 1px 2px #000000cc;
  --shady: #252525;
  --dark: #1c1c1c;
  --polkapink: #1bd3c5;
  --light-grey: #e7e7e7;
  --dark-grey: #ffffff;
  --size: 5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-grey);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
.pageBody,
#root {
  height: 100%;
  width: 100%;
  background-color: var(--background-primary);
  transition: background-color 0.5s linear;
}

.tabBody {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: var(--tabContainer);
  border-top: 0px;
  height: fit-content;
  box-shadow: var(--outer-shadow);
  flex-direction: column;
}

.play-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
  width: 100%;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
}

.tabBodyBet {
  width: 20%;
  max-width: 20%;
  margin-left: 10%;
  justify-content: center;
  position: relative;
  align-items: center;
  border-radius: 20px;
  background-color: var(--tabContainer);
  height: fit-content;
  box-shadow: var(--outer-shadow);
}

.tabBodyAccount {
  width: 25%;
  max-width: 25%;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: var(--tabContainer);
  margin-right: 1%;
  height: fit-content;
  box-shadow: var(--outer-shadow);
  padding: 15px;
}

.tabBodyBoard {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  height: fit-content;
  display: flex;
  margin-left: 1%;
  margin-right: 1%;
}

.board {
  display: inline-grid;
  padding: 10px;
  grid-template-columns: repeat(var(--size), 80px);
  grid-template-rows: repeat(var(--size), 80px);
  gap: 8px;
  background-color: #161616;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 15px;
}

.appName {
  width: auto;
  height: auto;
  max-width: 40%;
}

.board>* {
  width: 100%;
  height: 100%;
  display: flex;
  color: white;
  border: 2px solid #818181;
  user-select: none;
  transition: background-color 0.2s ease-in-out;
  border-radius: 5px;
}

.board>.tile.selected {
  background-color: #222222;
}

.board>.tile.hidden {
  background-color: #616161;
  cursor: pointer;
}

.board>.tile.win {
  background-image: url('../src/icons/win.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.board>.tile.lose {
  background-image: url('../src/icons/lose.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.board>.tile:hover {
  background-color: #ffffff3a;
}

.tabHeader {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabHeaderLeft {
  font-size: 18px;
  font-weight: 300;
}

.tabHeaderRight {
  background-color: #1cb477;
  color: var(--dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  width: auto;
  padding: 3%;
}

.bottomDiv {
  justify-content: center;
  align-items: center;
  border-radius: 19px;
}

.boxStyle {
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  margin: 1px auto;
  flex-direction: row;
  border-radius: 19px;
  position: relative;
  overflow: hidden;
  border: 1px solid grey;
}

.leftHeader {
  font-size: 40%;
}

.boxTemplate {
  width: auto;
  height: auto;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 25px 20px 25px;
  flex-direction: column;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  background-color: var(--background-primary);
}

.btn {
  background-color: var(--background-primary);
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  color: var(--dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 100px;
  height: 40px;
  border-radius: 15px;
  cursor: pointer;
  border: var(--border-primary);
  box-shadow: var(--outer-shadow);
}

.approvebtn {
  background-color: var(--background-primary);
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  color: var(--dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 100px;
  height: 40px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid #ffffff;
  box-sizing: border-box;
}

.approvebtn:active {
  box-shadow: var(--inner-shadow);
  background-color: #ffffff;
  color: white;
}

.btn:active {
  box-shadow: var(--inner-shadow);
  background-color: var(--polkapink);
  color: white;
}

.btn:hover {
  box-shadow: var(--inner-shadow);
}

.btn-disabled {
  background-color: gray;
  cursor: not-allowed;
}

.textField {
  width: 100%;
  height: 30px;
  font-size: 22px;
  background-color: var(--background-primary);
  border: 0px;
  font-weight: 800;
  color: #e9e9e9;
}

.settingsTextField {
  width: 190%;
  height: 30px;
  font-size: 12px;
  background-color: var(--background-primary);
  border: 0px;
  font-weight: 800;
  color: #e9e9e9;
}

.textField::placeholder {
  color: #464646;
  font-size: 22px;
  font-weight: 800;
}

.textField:focus-visible {
  outline: none;
}

.boxBody {
  display: flex;
  justify-content: space-between;
}

.centerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.accountIcon {
  width: 20px;
  margin-right: 5%;
}

.appnameIcon {
  height: auto;
  width: 200px;
}

.changeAccountIcon {
  color: var(--light-grey);
}

.logoutIcon {
  color: var(--light-grey);
}

.logout:hover .logoutIcon {
  color: var(--dark-grey);
}

.changeActiveAccountIcon:hover .changeAccountIcon {
  color: var(--dark-grey);
}

.changeActiveAccountIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 10%;
}

.miniText {
  color: var(--dark-grey);
  font-size: 15px;
  font-weight: 300;
  padding-top: 1%;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 10%;
}

.circle {
  border-radius: 50%;
  height: fit-content;
  padding: 2px;
  border: 2px solid var(--light-grey);
  display: flex;
  justify-content: center;
  width: fit-content;
}

.centerBody {
  display: flex;
  height: 80%;
  width: 100%;
}

.navBar {
  display: flex;
  align-items: center;
  position: sticky;
  padding: 0px 16px;
  height: 10%;
  box-shadow: 0px 16px 32px -16px #171717;
  background-color: var(--background-primary);
}

.howToPlayButton {
  margin-left: auto;
  height: 50%;
  width: 10%;
  max-width: 30%;
  background-color: #212121;
  color: var(--dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #ffffff;
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup {
  padding: 16px;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.popupbutton {
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 2px;
  width: 10%;
  background-color: var(--background-primary);
  color: var(--dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #ffffff;
  box-sizing: border-box;
}

.popupHeader {
  text-align: center;
  width: auto;
  height: auto;
  font-weight: bold;
  margin-top: 2%;
  margin-bottom: 2%;
}

.footer {
  display: flex;
  position: sticky;
  background-color: var(--background-primary);
  box-shadow: 0px -10px 32px -16px #171717;
  padding: 0px 16px;
  height: 10%;
}

.footerDisclaimer {
  display: flex;
  justify-content: space-between;
  max-width: 35%;
  font-size: 20%;
  color: #858585;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 12px 12px 12px;
  flex-direction: column;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  justify-content: center;
  background-color: #202020;
  text-align: justify;
  text-justify: inter-word;
}

.footerRight {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 300;
}

.footerLogo {
  height: 50%;
  width: auto;
  padding-left: 10px;
}

.howtoplaybox {
  width: auto;
  height: auto;
  display: flex;
  margin-left: 2%;
  margin-right: 2%;
  padding: 15px;
  flex-direction: column;
  border-radius: 15px;
  background-color: var(--background-primary);
  color: #c0c0c0;
  font-size: 14px;
}

.disclaimerbox {
  width: auto;
  height: auto;
  display: flex;
  text-align: justify;
  margin-left: 2%;
  margin-right: 2%;
  padding: 15px;
  flex-direction: column;
  border-radius: 15px;
  background-color: var(--background-primary);
  color: #c0c0c0;
  font-size: 14px;
}

.htpfootnote {
  font-size: 9px;
  color: #c0c0c0;
}

.stepsList {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.stepsList li {
  margin-bottom: 5px;
}

.hyperlink {
  color: #1bd3c5;
}

@media (max-width: 1081px) {
  .popupbutton {
    margin-top: 4%;
    margin-bottom: 1%;
    padding: 10px;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    box-sizing: border-box;
  }

  .htpfootnote {
    font-size: 6px;
  }

  .tabBody {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding: 3%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  .howtoplaybox {
    width: auto;
    height: auto;
    display: flex;
    margin: 0%;
    padding: 3%;
    flex-direction: column;
    border-radius: 15px;
    font-size: 9px;
  }

  .disclaimerbox {
    width: auto;
    height: auto;
    display: flex;
    margin: 0%;
    padding: 3%;
    flex-direction: column;
    border-radius: 15px;
    font-size: 9px;
  }

  .footer {
    display: flex;
    position: sticky;
    background-color: var(--background-primary);
    box-shadow: 0px -10px 32px -16px #171717;
    padding: 3%;
    height: 8%;
    margin-top: 2%;
  }

  .navBar {
    width: 100%;
    height: 8%;
    padding: 0%;
  }

  .appnameIcon {
    height: auto;
    width: 150px;
    margin-left: 2%;
  }

  .howToPlayButton {
    margin-left: auto;
    margin-right: 2%;
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    border-radius: 10px;
  }

  .tabHeader {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 4%;
    padding-top: 4%;
  }

  .tabHeaderLeft {
    font-size: 14px;
    font-weight: 300;
  }

  .btn {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 12px;
    width: 35%;
    height: 25px;
    border-radius: 5px;
  }

  .approvebtn {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 12px;
    width: 35%;
    height: 25px;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  .bottomDiv {
    display: flex;
    border-radius: 0px;
    flex-direction: row;
  }

  .boxTemplate {
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 2%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 3%;
    border-radius: 15px;
  }

  .textField {
    width: 100%;
    height: 20px;
    font-size: 22px;
    font-weight: 800;
  }

  .tabBodyBet {
    width: 80%;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    border-radius: 20px;
    height: auto;
  }

  .tabBodyBoard {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    margin: 0%;
  }

  .board {
    padding: 0px;
    grid-template-columns: repeat(var(--size), 45px);
    grid-template-rows: repeat(var(--size), 45px);
    gap: 8px;
    border-radius: 0px;
    margin-bottom: 0px;
  }

  .tabBodyAccount {
    margin-top: 5%;
    width: 80%;
    max-width: 80%;
    border-radius: 20px;
    margin-right: auto;
    margin-left: auto;
    padding: 5%;
  }

  .play-container {
    flex-direction: column;
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    justify-content: flex-start;
  }

  .footerDisclaimer {
    max-width: 50%;
    font-size: 6px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 2%;
    border-radius: 5px;
    position: relative;
    overflow: visible;
  }

  .offlinelogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 200px;
}

  .offlinelogo img {
    max-width: 100%;
    height: auto;
  }

  .dsma {
    font-size: 9px;
    padding: 1%;
  }
}

.version {
  font-size: 12px;
  font-weight: 300;
  color: #a5a5a5;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
}

.error {
  color: var(--dark-grey);
  display: flex;
  justify-content: flex-start;
  padding: 0px 20px;
}

.accountDetail {
  background-color: var(--background-primary);
  width: 100%;
  height: 55px;
  border-radius: 15px;
  border: var(--border-primary);
  box-shadow: var(--inner-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountDetailsText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  padding-left: 10px;
}

.accountdetailIcon {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountDetailHeader {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.accountDetailAddress {
  font-size: 12px;
  color: var(--dark-grey);
  padding-top: 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.accountWrapper {
  margin-top: 5%;
  margin-bottom: 5%;
  border: var(--border-primary);
  border-radius: 15px;
  height: 55px;
  box-shadow: var(--inner-shadow);
  background-color: var(--background-primary);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accountWrapper:hover {
  border: var(--dark);
}

.accountTextSection {
  padding: 5%;
  width: 75%;
}

.accounticon {
  margin: 3%;
}

.accountConnectBtn {
  border: var(--border-primary);
  background-color: var(--background-primary);
  color: var(--dark-grey);
  border-radius: 15px;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
}

.accountConnectBtn:hover {
  color: white;
  box-shadow: var(--inner-shadow);
}

.Assets {
  padding: 0%;
}

.assetsHeading {
  font-size: 16px;
  font-weight: 300;
  color: var(--dark-grey);
  margin-top: 15px;
  margin-bottom: 15px;
}

.assetBody {
  background-color: var(--background-primary);
  border: var(--border-primary);
  box-shadow: var(--inner-shadow);
  border-radius: 15px;
  height: fit-content;
  padding: 5px;
  margin-top: 5%;
}

.row {
  width: 100%;
  display: flex;
  height: 30px;
}

.attributeName {
  font-size: 14px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-grey);
}

.attributeValue {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-grey);
}

.customAlertBox {
  background-color: #1bd3c5;
  color: #000000;
  font-weight: 300;
  border-radius: 2px;
  padding: 1%;
}

.customAlert {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 1%;
  outline: none;
  opacity: 0;
  animation: fadeOut 2s ease-in-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.customAlert.active {
  opacity: 1;
}

.loading-spinner {
  width: 15px;
  height: 15px;
  margin-left: 3%;
}

.offlinelogo {
  display: flex;
  justify-content: center;
  width: 70%;
  height: 70%;
}

.dsma {
  color:#969696;
}

.checkboxcont {
  margin-top: 1%;
}